import axios from 'axios';
import moment from 'moment';
import React,{useContext, useEffect, useState,Fragment} from 'react';
import { AuthContext } from '../../App';
import url from '../url';

import User from '../../images/new/149071.png'
import { Dialog, Transition } from '@headlessui/react'
import Spo2Icon from '../../images/new/Spo2 01.png'
import ResIcon from '../../images/new/BR 01.png'
import HeartIcon from '../../images/new/HR 01.png'
import ClIcon from '../../images/new/Cholerestrol 01.png'
import BSicon from '../../images/new/Sugar level 01.png'
import { Link } from 'react-router-dom';

import JoinButton from '../../images/DoctorHMSAssets/join-now-button-768x202-1.png'


const TodayAppointment = () => {
    const [patient, setPatient]=useState('')
    let [isOpen, setIsOpen] = useState(false)
    const {auth}= useContext(AuthContext);

    const [vitals,setVitals]=useState([])
    const [patients, setPatients]=useState([])
    const [patientid, setPatientId]=useState(0)
    const [name,setName]=useState('')
    const [message,setMessage]=useState('')


    useEffect(()=>{
    

      const intervalId = setInterval(() => {
        getPatients()
        
       
      }, 1000 * 5) // in milliseconds
      return () => clearInterval(intervalId)
      
       
      
     },[])



    const getPatientVitals=()=>{
        //    console.log(patientid)
            axios.get(url+`hms/patient/view?user_id=${patientid}`,{ headers: {"Authorization" : `Bearer ${auth.token}`} })
                .then(res=>{
                    console.log(res.data.result)
                    setVitals(res.data.result)
                    setIsOpen(true)

                }).catch(e=>{
                    console.log(e);

                }).then(r=>{
                    console.log(r)

                })
        }


        useEffect(()=>{
            getPatients()

        },[])
        const getPatients=()=>{
            axios.get(url+'hms/doctor/getDailyPendingAppointments',{ headers: {"Authorization" : `Bearer ${auth.token}`} })
                .then(res=>{
                    console.log('Today Appointment')
                    console.log(res)
                    // console.log(res)
                    // setVitals(res.data.result)
                    //  setPatients(res)
                    //  openModal()
                    const result = Array.isArray(res.data.result);
                    if(result){
                      // console.log('array')
                      setPatients(res.data.result)
                    }else{
                      // console.log('string')
                      setMessage(res.data.result)
                    }
                    // console.log('res.data.result.page')
                    // console.log(res.data.result.activePage)

                    // setActivePage(res.data.result.current_page)
                    // setTotalItems(res.data.result.total)
                    // setPerPage(res.data.result.per_page)

                }).catch(e=>{
                    console.log(e);

                }).then(r=>{
                    console.log(r)

                })

        }
        const viewHistory=(name,id)=>{
            setName(name)
            setPatientId(id)
            getPatientVitals()
        }

        const joinMeeting=(patient_id,doc_id,meeting_link)=>{
          window.open(meeting_link, "_blank")
        }

        const renderpatients=patients.map((patient,index)=>(
          <div className=' w-full py-1 bg-green-50'>
                      <div className='flex flex-row mr-3 bg-white rounded-lg'>
                      <div className=''>
                                    {/* imag */}
                                    <div class="relative w-16 h-16">
                                        <img class="rounded-full border-2 border-blue-500 shadow-sm" src={User} alt="user image" />
                                        {/* <div class="absolute top-0 right-0 h-4 w-4 my-1 border-2 border-white rounded-full bg-green-400 z-2"></div> */}
                                    </div>

                                </div>
                            <div className='w-1/2 flex flex-col ml-3 space-y-1'>
                                <div className='text-blue-500 text-1xl font-bold'>

                                  {patient.data.patient_name}

                                </div>
                                <div >
                                    <span className='text-blue-500'>Latest:</span>  Vitals
                                </div>
                                <div onClick={()=>viewHistory(patient.data.patient_name.split(' ')[0],patient.id)} >
                                    <button>


                                <i class="fa-solid fa-file-lines text-blue-500 mr-2" onClick={()=>viewHistory(patient.data.patient_name.split(' ')[0],patient.id)}></i>  View History
                                </button>
                                </div>

                            </div>
                            <div className='w-1/2 flex flex-row justify-between mx-2'>
                                <div className='text-gray-500 my-auto'>
                                   <span className='inline-block align-middle m-auto font-black'>
                                    {moment(patient.data.appointment_date).format('LT')}
                                   </span>


                                </div>


                            </div>

                            <div className='w-1/2 flex flex-row justify-between mx-2'>
                            <div className='flex flex-row py-5'>
                            <Link to={`/${patient.data.meeting_link}/${patient.id}`}>
                          <button type="button" class="text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2 ">Join</button>

                          </Link>
                            </div>
                            </div>

                        </div>
                    </div>

        ))
        function closeModal() {
            setIsOpen(false)
        }

        function openModal() {
            setIsOpen(true)
        }




        const rendervitals= vitals.map((vital,index)=>{
            // console.log('nafika hapa')

            if(vital.diagnosis){
                // diagnosis
                return (
                    <div className='md:w-1/3 w-full'>
                                      <div className='border-2 border-blue-500 rounded-lg mx-2 flex flex-col p-2'>
                                          <div className='flex text-blue-500 text-sm'>
                                          {/* 2022-06-05 07:45:08 AM */}
                                          {/* {} */}
                                          {moment(vital.created_at).format('YYYY-DD-MM')} {'('+moment(vital.created_at).format('LTS')+')'}


                                          </div>
                                          <div className='font-bold text-gray-600'>
                                               Vitals

                                          </div>
                                          <div className='flex text-blue-500 text-sm'>




                                          </div>
                                          {/* <div className='flex text-blue-500 text-sm'>



                                          </div> */}
                                          <div className='flex flex-col space-y-3'>
                                              {/* Vital Signs */}

                                              {vital.Spo2?<>
                                                <div className='flex flex-row'>

                                                {/* className="bg-no-repeat h-28 bg-contain mr-4"  */}
                                                  <div className='w-1/4 p-2 bg-red-600 rounded-l-lg g-cover bg-center' style={{ backgroundImage: `url(${Spo2Icon})`}}>
                                                      <img src={Spo2Icon} className=' h-12' />
                                                  </div>
                                                  <div className='flex flex-auto bg-red-200 flex-col mx-auto rounded-r-lg text-black font-bold'>
                                                     <div className='mx-auto'>
                                                     SpO2 Level
                                                     </div>
                                                     <div className='flex mx-auto'>
                                                     {vital.Spo2}%

                                                     </div>

                                                  </div>

                                              </div>

                                                </>:<>

                                                </>}


                                                {vital.br?<>
                                                    <div className='flex flex-row'>
                                                  <div className='w-1/4 p-2 bg-pink-400 rounded-l-lg'>
                                                      <img src={ResIcon} className=' w-24 mx-auto' />
                                                  </div>
                                                  <div className='flex flex-auto bg-pink-100 flex-col mx-auto rounded-r-lg text-black font-bold'>
                                                     <div className='mx-auto'>
                                                     Breathing Rate
                                                     </div>
                                                     <div className='flex mx-auto'>
                                                     {vital.br} RPM

                                                     </div>

                                                  </div>

                                              </div>

                                                    </>:<>

                                                    </>}



                                              {vital.hr?<>
                                                <div className='flex flex-row'>
                                                  <div className='w-1/4 p-2 bg-blue-400 rounded-l-lg'>
                                                      <img src={HeartIcon} className='h-10 mx-auto' />
                                                  </div>
                                                  <div className='flex flex-auto bg-blue-200 flex-col mx-auto rounded-r-lg text-black font-bold'>
                                                     <div className='mx-auto'>
                                                     Heart Rate
                                                     </div>
                                                     <div className='flex mx-auto'>
                                                     {vital.hr}  BPM

                                                     </div>

                                                  </div>

                                              </div>

                                              </>:<>

                                              </>}
                                              {vital.bloodpressure?<>
                                                <div className='flex flex-row'>
                                                  <div className='w-1/4 p-2 bg-blue-400 rounded-l-lg'>
                                                      <img src={HeartIcon} className='h-10 mx-auto' />
                                                  </div>
                                                  <div className='flex flex-auto bg-blue-200 flex-col mx-auto rounded-r-lg text-black font-bold'>
                                                     <div className='mx-auto'>
                                                     Blood Pressure
                                                     </div>
                                                     <div className='flex mx-auto'>
                                                     {vital.bloodpressure}  <sub className='mt-3'>mmHg</sub>

                                                     </div>

                                                  </div>

                                              </div>

                                              </>:<>

                                              </>}
                                              {vital.cholestrol?<>
                                                <div className='flex flex-row'>
                                                  <div className='w-1/4 p-2 bg-orange-400 rounded-l-lg'>
                                                      <img src={ClIcon} className='h-10 mx-auto' />
                                                  </div>
                                                  <div className='flex flex-auto bg-orange-200 flex-col mx-auto rounded-r-lg text-black font-bold'>
                                                     <div className='mx-auto'>
                                                     Cholesterol
                                                     </div>
                                                     <div className='flex mx-auto'>
                                                     {vital.cholestrol} mg/dL

                                                     </div>

                                                  </div>

                                              </div>

                                              </>:<>

                                              </>}
                                              {vital.bloodsugar?<>
                                                <div className='flex flex-row'>
                                                  <div className='w-1/4 p-2 bg-orange-600 rounded-l-lg'>
                                                      <img src={BSicon} className='h-10 mx-auto' />
                                                  </div>
                                                  <div className='flex flex-auto bg-orange-300 flex-col mx-auto rounded-r-lg text-black font-bold'>
                                                     <div className='mx-auto'>
                                                     Blood Sugar
                                                     </div>
                                                     <div className='flex mx-auto'>
                                                     {vital.bloodsugar}  <sub className='mt-3'>mmol/L</sub>

                                                     </div>

                                                  </div>

                                              </div>

                                              </>:<>

                                              </>}



                                          </div>


                                      </div>

                                  </div>
                )

            }


    })
    return (<>
    <div className=' rounded-lg bg-white p-3'>
    <div className='overflow-scroll h-72'>
    {renderpatients}
    </div>
    <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="md:w-2/3 w-3/4 transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                      <div className='flex flex-row justify-between mb-6'>
                          <div className='flex flex-col space-y-3'>
                          <div className='text-blue-500 text-2xl font-bold'>
                              {name}

                          </div>
                          <div className='text-gray-700'>
                               Vitals

                          </div>

                          </div>
                          <div>
                          <div class="relative w-24 h-24 mx-auto">
                                <img class="rounded-full border-2 border-blue-500 shadow-sm" src={User} alt="user image" />
                                {/* <div class="absolute top-0 right-0 h-6 w-6 my-1 border-4 border-white rounded-full bg-gray-300 z-2"></div> */}
                            </div>

                          </div>


                      </div>


                  </Dialog.Title>
                  <div className="mt-2 flex flex-col">
                      <div className='flex md:flex-row flex-col flex-wrap space-y-6'>

                          {rendervitals}










                      </div>

                  </div>

                  <div className="mt-4 flex flex-row justify-end">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      X
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

    </div>

    </>  );
}

export default TodayAppointment;
