import React from 'react';
import MainContent from './MainContent';

import DocNav from './DocNav';
import JitsiSDK from '../Conference/JitsiSDK';

const DocMeeting = () => {
    return ( 
        <div>
    <div class="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-green-50  text-black ">

      {/* Navigation Here */}
      <DocNav />
    
      
      <div  class="h-full ml-14 mt-14 mb-10 md:ml-64">
          {/* Main content here */}
      <JitsiSDK />

      </div>
      
    </div>
  </div>    

  
     );
}
 
export default DocMeeting;