import React, { useContext, useEffect, useState } from 'react';
import MainContent from './MainContent';

import {AuthContext} from '../../App'
import { Link } from 'react-router-dom';

import DocNav from './DocNav';
import axios from 'axios';
import url from '../url';
import uuid from 'react-uuid'
import moment from 'moment';



const Appointments = () => {
  const {auth}=useContext(AuthContext)

  const [appointments, setAppointments]=useState([])
  // const []

  var user2 = JSON.parse(auth.user)
  

  

  useEffect(()=>{
    getNot()

  },[])

    
    useEffect(()=>{
      getNot()

      const intervalId = setInterval(() => {
        getNot()
       
      }, 1000 * 5) // in milliseconds
      return () => clearInterval(intervalId)
      
       
      
     },[])

 

  const getNot=()=>{
    // alert(user2.id)
    axios.get(url+`hms/doctor/getNotification?doctor_id=${user2.id}&recordsPerPage=50`,{ headers: {"Authorization" : `Bearer ${auth.token}`} })
      .then(r=>{
        setAppointments(r.data.result)
        console.log(r)
      }).catch(e=>{
        console.log(e)
      })
  }

  const acceptAppointment=(patient_id,doc_id,id)=>{
    let uid= 'docsun_meet'+uuid()
        let meetingurl= `meeting/${patient_id}/${user2.id}/${uid}`
    const config={
      // "Authorization" : `Bearer ${auth.token}`} 
      headers: { Authorization: `Bearer ${auth.token}` }

    }
  
  const params={
    id: id,
    patient_id:patient_id,
    meeting_id:uid,
    meeting_link:meetingurl,
    status:0
  }
    axios.post(url+'hms/doctor/acceptNotification',params,config)
    .then(res=>{
      // console.log(res)

      alert('done')
      getNot()

    }).catch(e=>{
      console.log(e)

    }).then(r=>{

    })
  }

  const renderdata= appointments.map((appointment,index)=>(

    <tr class="bg-gray-50  hover:bg-gray-100 ">
                    {/* <td class="px-4 py-3">
                      <div class="flex items-center text-sm">
                        
                        <div>
                          <p class="font-semibold">{appointment.data.id}</p>
                          
                        </div>
                      </div>
                    </td> */}
                    <td class="px-4 py-3 text-sm">{appointment.data.patient_name}</td>
                    {/* <td class="px-4 py-3 text-xs">
                      Male
                    </td> */}
                    <td class="px-4 py-3 text-sm"> {moment(appointment.data.appointment_date).format('DD/MM/YYYY')}
                     </td>
                    <td class="px-4 py-3 text-sm">{moment(appointment.data.appointment_date).format('LT')}</td>
                    <td class="px-4 py-3 text-sm">
                        <div className='flex flex-row'>
                          {appointment.data.meeting_id==''?
                          <>
                          <button type="button" class="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-full border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Cancel</button>
                        <button type="button" class="text-white bg-red-700 hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2 " onClick={()=>acceptAppointment(appointment.data.patient_id,appointment.data.doctor_id,appointment.id)}>Approve</button>
                          </>:
                          <>
                          <Link to={`/${appointment.data.meeting_link}`}>
                          <button type="button" class="text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2 ">Join</button>

                          </Link>
                          
                          </>}
                        

                        </div>
                    </td>
                  </tr>

  ))
    return ( 
        <div>
    <div class="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-green-50  text-black ">

      {/* Navigation Here */}
      <DocNav />
    
      
      <div  class="h-full ml-14 mt-14 mb-10 md:ml-64">
          {/* Main content here */}
      {/* <MainContent /> */}
      {/* <!-- Client Table --> */}
      <div className='text-3xl fornt-extrabold font-black'>
          Appointments
      </div>
      <div class="mt-4 mx-4">
          <div class="w-full overflow-hidden rounded-lg shadow-xs">
            <div class="w-full overflow-x-auto">
              <table class="w-full">
                <thead>
                  <tr class="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b  bg-gray-50 ">
                    {/* <th class="px-4 py-3">ID</th> */}
                    <th class="px-4 py-3">Name</th>
                    {/* <th class="px-4 py-3">Gender</th> */}
                    <th class="px-4 py-3">Requested Date</th>
                    <th class="px-4 py-3">Time</th>
                    <th class="px-4 py-3">&nbsp;</th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y ">
                  {renderdata}
                  
                  
                 
                  
                </tbody>
              </table>
            </div>
           
          </div>
        </div>

      </div>
      
    </div>
  </div>    

  
     );
}
 
export default Appointments;