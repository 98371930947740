import React, { useContext, useEffect, useState } from 'react';
import MainContent from './MainContent';

import DocNav from './DocNav';
import TP from '../../images/DoctorHMSAssets/Total Patients@2.1x.png'
import PA from '../../images/DoctorHMSAssets/Pending Appointments @2.1x.png'
import PR from '../../images/DoctorHMSAssets/Pending Requests@2.1x.png'
import TodayAppointment from './TodayAppointment';
import axios from 'axios';
import url from '../url';
import { AuthContext } from '../../App';
import AppointmentRequest from './AppointmentRequest';
const DocDashboard = () => {
  const [totalpatients, setTotalPatients]=useState('')
  const [pendingappoitments, setPendingAppointments]=useState('')
  const [pendingrequests, setPendingRequests]=useState('')

  const {auth}=useContext(AuthContext)

  useEffect(()=>{
    getTotalPatients()
    getPendingAppointment()
    getPendingRequests()

  },[])

  useEffect(()=>{
    

    const intervalId = setInterval(() => {
      getTotalPatients()
    getPendingAppointment()
    getPendingRequests()
     
    }, 1000 * 5) // in milliseconds
    return () => clearInterval(intervalId)
    
     
    
   },[])

  const getTotalPatients=()=>{
    axios.get(url+'hms/doctor/getPatientCount',{ headers: {"Authorization" : `Bearer ${auth.token}`} })
      .then(res=>{
        // console.log(res)
        setTotalPatients(res.data.result)
      }).catch(e=>{
        

        console.log(e)

      }).then(r=>{

      })
  }
  const getPendingAppointment=()=>{
    axios.get(url+'hms/doctor/getAcceptedJoin',{ headers: {"Authorization" : `Bearer ${auth.token}`} })
      .then(res=>{
        // console.log(res)
        setPendingAppointments(res.data.result)
      }).catch(e=>{
        

        console.log(e)

      }).then(r=>{

      })
  }
  const getPendingRequests=()=>{
    axios.get(url+'hms/doctor/getAcceptReject',{ headers: {"Authorization" : `Bearer ${auth.token}`} })
      .then(res=>{
        // console.log(res)
        setPendingRequests(res.data.result)
      }).catch(e=>{
        

        console.log(e)

      }).then(r=>{

      })
  }

    return ( 
        <div>
    <div class="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased  text-black bg-blue-50 ">

      {/* Navigation Here */}
      <DocNav />
    
      
      <div  class="h-full ml-14 mt-14 mb-10 md:ml-64 bg-blue-50">
          {/* Main content here */}
      {/* <MainContent /> */}
      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 p-4 gap-4">
          <div class="bg-white  shadow-lg rounded-md flex items-center justify-between p-3 border-b-4  font-medium group">
            <div class="flex justify-center items-center w-16 h-16 bg-white rounded-full transition-all duration-300 transform group-hover:rotate-12">
            <img src={TP} />
              {/* <svg width="30" height="30" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="stroke-current text-blue-800 transform transition-transform duration-500 ease-in-out"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"></path></svg> */}
            </div>
            <div class="text-right">
              
              <p className='text-gray-600'>Total Patients</p>
              <p class="text-3xl text-green-500 font-bold">{totalpatients}</p>
            </div>
          </div>
          <div class="bg-white  shadow-lg rounded-md flex items-center justify-between p-3 border-b-4    font-medium group">
            <div class="flex justify-center items-center w-16 h-16 bg-white rounded-full transition-all duration-300 transform group-hover:rotate-12">
              {/* <svg width="30" height="30" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="stroke-current text-blue-800  transform transition-transform duration-500 ease-in-out"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"></path></svg> */}
              <img src={PA} />
            </div>
            
            <div class="text-right">
              
              <p className='text-gray-500'>Pending Appointments</p>
              <p class="text-3xl text-pink-400 font-bold">{pendingappoitments}</p>
            </div>
          </div>
          <div class="bg-white  shadow-lg rounded-md flex items-center justify-between p-3 border-b-4   ont-medium group">
            <div class="flex justify-center items-center w-16 h-16 bg-white rounded-full transition-all duration-300 transform group-hover:rotate-12">
              {/* <svg width="30" height="30" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="stroke-current text-blue-800 dark:text-gray-800 transform transition-transform duration-500 ease-in-out"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"></path></svg> */}
              <img src={PR} />
            </div>
            <div class="text-right">
            <p className='text-gray-500'>Pending Requests</p>
            <p class="text-3xl font-bold text-orange-300">{pendingrequests}</p>
             
              
              
            </div>
          </div>
          {/* <div class=" shadow-lg rounded-md flex items-center justify-between p-3 border-b-4    font-medium group">
            <div class="flex justify-center items-center w-14 h-14 bg-white rounded-full transition-all duration-300 transform group-hover:rotate-12">
              <svg width="30" height="30" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="stroke-current text-green-800  transform transition-transform duration-500 ease-in-out"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
            </div>
            <div class="text-right">
              <p>Avg. Call Duration</p>
              <p class="text-2xl">0</p>
              
            </div>
          </div> */}
        </div>
        <div className='flex flex-col md:flex-row p-4 space-x-3 '>
        <div className='md:w-1/2 flex flex-col'>
            <div className='font-bold my-3'>

              Today's Appointment

            </div>
            <div>
              <TodayAppointment />

            </div>
        </div>
        <div className='md:w-1/2 flex flex-col'>
         
          <div className='font-bold my-3'>
            Appointment Request
            
          </div>
          <div>
            <AppointmentRequest />

          </div>

        </div>

      </div>

      </div>

      
      
    </div>
  </div>    

  
     );
}
 
export default DocDashboard;