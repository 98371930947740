import { JitsiMeeting } from '@jitsi/react-sdk';
import axios from 'axios';
import React, { useContext, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { AuthContext } from '../../App';
import url from '../url';

const JitsiSDK = () => {
    const {auth}=useContext(AuthContext)

    let {user,doctor,meetingid,nodid}=useParams()

    var user2 = JSON.parse(auth.user)


    useEffect(()=>{
        joinedmeeting()
        

    },[])

    useEffect(() => {
        const timer = setTimeout(() => {
        //   console.log('This will run after 1 second!')
        delNot()
        }, 5000);
        return () => clearTimeout(timer);
      }, []);

    const delNot=()=>{
        const config = {
            headers: { Authorization: `Bearer ${auth.token}` }
        };
        const params={
            id:nodid
        }
        axios.post(url+'hms/doctor/delNotification',params,config)
        .then(res=>{
            console.log(res)
        }).catch(e=>{
            console.log(e)
        })
    }
    const joinedmeeting=()=>{
        const config = {
            headers: { Authorization: `Bearer ${auth.token}` }
        };
        const params={
            id:nodid
        }
        axios.post(url+'hms/doctor/joinMeeting',params,config)
        .then(res=>{
            console.log(res)
        }).catch(e=>{
            console.log(e)
        })
    }

   
    return ( <div className='flex flex-col'>

   
<div className='my-4'>


</div>
<JitsiMeeting
    // domain = { 'doctor-test.docsun.health' }
    roomName = {meetingid}
    configOverwrite = {{
        startWithAudioMuted: true,
        disableModeratorIndicator: true,
        startScreenSharing: true,
        enableEmailInStats: false
    }}
    interfaceConfigOverwrite = {{
        DISABLE_JOIN_LEAVE_NOTIFICATIONS: true
    }}
    userInfo = {{
        displayName: user2.name
    }}
    // participantLeft
    
    onApiReady = { (externalApi) => {

        console.log(externalApi)
        // here you can attach custom event listeners to the Jitsi Meet External API
        // you can also store it locally to execute commands
    } }
    // getIFrameRef = { (iframe) => { iframeRef.style.height = 400; } }
    getIFrameRef = { node => (node.style.height = '500px',node.style.width = '1000px') }
/>

<div className='flex flex-col'> 


<label for="message" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">Patient's Report</label>
<textarea id="message" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500" placeholder="Patient's notes"></textarea>

    
</div>

<div className='my-3'>
    <button type='button' class="text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2">
        Save Notes
    </button>

</div>

    
    </div> );
}
 
export default JitsiSDK;